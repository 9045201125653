$ ->
  submitting = false

  $form = $('form#contact-form')
  $submitButton = $('button[type=submit]', $form)

  setStatus = (text) ->
    $('#contact-form-status').text(text)

  $form.on 'submit', (event) ->
    event.preventDefault()

    submitting = true
    $submitButton.hide()
    $form.prop 'disabled', true
    setStatus 'Sending'

    formData = {}
    $.each $form.serializeArray(), (_, kv) ->
      formData[kv.name] = kv.value

    $.post('/contact', formData)
      .done () ->
        setStatus 'Sent successfully. Thank you for your enquiry.'
        $form.remove()

      .fail () ->
        setStatus 'Sending failed. Please try again.'
        $submitButton.show()
        $form.prop 'disabled', false
