window.bfMaps =
  renderMaps: (maps) ->
    if window.google?.maps
      ((item, i) ->
        map = new window.google.maps.Map(item.element, item.options())
        item.callback(map) if item.callback?
      )(item, i) for item, i in maps

  loadGoogleMapsScript: (apiKey, callbackName) ->
    script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = "https://maps.googleapis.com/maps/api/js?key=#{apiKey}&callback=#{callbackName}"
    document.body.appendChild(script)
