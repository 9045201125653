$ ->

  $(window).on 'resize', (event) ->
    $img = $('#modal img')
    setImgMaxHeight($img) if $img.length

  setImgMaxHeight = ($img) ->
    imgOffset = $img.offset()
    verticalSpacing = imgOffset.top - $(window).scrollTop()

    modalImgMaxHeight = $(window).height() - (verticalSpacing * 2)
    $img.css({maxHeight: modalImgMaxHeight})

  setControls = ($slideshow, itemCount, index) ->
    if index >= itemCount - 1
      $('.slideshow-controls .next', $slideshow).addClass('hidden')
    else
      $('.slideshow-controls .next', $slideshow).removeClass('hidden')

    if index > 0
      $('.slideshow-controls .prev', $slideshow).removeClass('hidden')
    else
      $('.slideshow-controls .prev', $slideshow).addClass('hidden')

  openModal = () ->
    $('#modal').removeClass('hidden')

  closeModal = () ->
    $(window).off 'keydown.modal'
    $('#modal').addClass('hidden')

  setModal = (slides, i) ->
    setModalSlide(slides[i])
    setModalControls(slides.length, i)

    $('#modal').on 'click', '.slideshow-controls .next', (event) ->
      event.preventDefault()
      event.stopPropagation()
      nextSlide()

    $('#modal').on 'click', '.slideshow-controls .prev', (event) ->
      event.preventDefault()
      event.stopPropagation()
      prevSlide()
      
    nextSlide = () ->
      if i < slides.length - 1
        i++
        setModalSlide(slides[i])
        setModalControls(slides.length, i)

    prevSlide = () ->
      if i > 0
        i--
        setModalSlide(slides[i])
        setModalControls(slides.length, i)

    $(window).on 'keydown.modal', (event) ->
      if event.which in [37, 39, 27, 88]
        event.preventDefault()
        event.stopPropagation()
      
        prevSlide() if event.which is 37 # Left arrow
        nextSlide() if event.which is 39 # Right arrow
        closeModal() if event.which in [27, 88] # Escape, x

  setModalSlide = (src) ->
    $img = $('<img>', {src: src})
    $('#modal .image').html($img)
    setImgMaxHeight($img)

  setModalControls = (total, i) ->
    $('#modal .slideshow-controls .prev').toggleClass('hidden', i < 1)
    $('#modal .slideshow-controls .next').toggleClass('hidden', i >= total - 1)



  $('.slideshow').on 'click', '.slideshow-controls .prev', (event) ->
    event.preventDefault()

    $slideshow = $ event.delegateTarget
    $currentItem = $('.slideshow-item.active', $slideshow)
      .removeClass('active')
      .addClass('hidden')

    currentIndex = $currentItem.index()
    prevIndex = currentIndex - 1

    prevItemEl = $('.slideshow-item', $slideshow)[prevIndex]

    $(prevItemEl).removeClass('hidden')
      .addClass('active')

    itemCount = $('.slideshow-item', $slideshow).size()
    setControls $slideshow, itemCount, prevIndex

  $('.slideshow').on 'click', '.slideshow-controls .next', (event) ->
    event.preventDefault()

    $slideshow = $ event.delegateTarget
    $currentItem = $('.slideshow-item.active', $slideshow)
      .removeClass('active')
      .addClass('hidden')

    currentIndex = $currentItem.index()
    nextIndex = currentIndex + 1

    nextItemEl = $('.slideshow-item', $slideshow)[nextIndex]

    $(nextItemEl).removeClass('hidden')
      .addClass('active')

    itemCount = $('.slideshow-item', $slideshow).size()
    setControls $slideshow, itemCount, nextIndex

  $('.slideshow').on 'click', '.slideshow-item [data-src-large]', (event) ->
    event.preventDefault()

    currentSlide = $(this).data('srcLarge')
    $slideshow = $ event.delegateTarget

    slides = []

    $slideshow.find('.slideshow-item [data-src-large]')
      .each((i, element) ->
        slides.push $(element).data('srcLarge')
      )

    currentIndex = slides.indexOf currentSlide

    openModal()
    setModal(slides, currentIndex)

  $('#modal').on 'click', (event) ->
    event.preventDefault()
    closeModal()
