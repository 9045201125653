$ ->
  hiddenClass = 'hidden'

  showSignupForm = () ->
    $('#call-to-action').addClass(hiddenClass)
    $('#signup-form').removeClass(hiddenClass)

  hideSignupForm = () ->
    $('#call-to-action').removeClass(hiddenClass)
    $('#signup-form').addClass(hiddenClass)

  $('#signup-start').click (event) ->
    event.preventDefault()
    showSignupForm()
    ga('send', 'event', 'promotion', 'click button', 'Enter Now')

  $('#signup-form form').on 'submit', (event) ->
    ga('send', 'event', 'promotion', 'submit form', 'Subscribe and Enter')
